// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".period_filter_D89ph {\n  display: flex;\n  align-items: center;\n}\n.period_filter_date_picker_rw1gB .ant-form-item-control-input {\n  padding-bottom: 0px !important;\n}\n.period_filter_select_qDhbb {\n  width: 100px;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/Table/components/PeriodFilter/styles.m.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EAGM,8BAAA;AADR;AAME;EACE,YAAA;AAJJ","sourcesContent":[".period_filter {\n  display: flex;\n  align-items: center;\n\n  &_date_picker {\n    :global {\n      .ant-form-item-control-input {\n        padding-bottom: 0px !important;\n      }\n    }\n  }\n\n  &_select {\n    width: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"period_filter": "period_filter_D89ph",
	"period_filter_date_picker": "period_filter_date_picker_rw1gB",
	"period_filter_select": "period_filter_select_qDhbb"
};
export default ___CSS_LOADER_EXPORT___;
