import { DateItemProps } from "../../../DateItem"
import { PeriodSelectOptions } from "./types"

const mapPeriodNameToSelectOptionLabel: Record<PeriodSelectOptions, string> = {
  [PeriodSelectOptions.DATE]: 'Дата',
  [PeriodSelectOptions.DATE_RANGE]: 'Период',
  [PeriodSelectOptions.MONTH]: 'Месяц',
  [PeriodSelectOptions.QUARTER]: 'Квартал',
  [PeriodSelectOptions.YEAR]: 'Год'
}

export const mapPeriodNameToPickerProps: Record<PeriodSelectOptions, Partial<DateItemProps>> = {
  [PeriodSelectOptions.DATE]: {
    placeholder: 'дд.мм.гггг',
    format: 'DD.MM.YYYY'
  },
  [PeriodSelectOptions.DATE_RANGE]: {
    placeholder: 'дд.мм.гггг',
    format: 'DD.MM.YYYY'
  },
  [PeriodSelectOptions.MONTH]: {
    placeholder: 'мм.гггг',
    format: 'MM.YYYY'
  },
  [PeriodSelectOptions.QUARTER]: {
    placeholder: undefined,
    format: undefined
  },
  [PeriodSelectOptions.YEAR]: {
    placeholder: 'гггг',
    format: 'YYYY'
  }
}

export const selectOptions = Object.entries(mapPeriodNameToSelectOptionLabel).map(([value, label]) => ({
  label,
  value
}))