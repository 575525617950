import { FC, useState } from "react"
import styles from './styles.m.less'
import moment, { Moment } from "moment"
import { PeriodSelectOptions } from "./types"
import { mapPeriodNameToPickerProps, selectOptions } from "./utils"
import { DatePicker } from "antd"
import WrappedDateItem from "../../../DateItem"
import { Select } from "../../../components/Form"
import WrappedDateRangeItem, { DateRangeItemProps } from "../../../DateRangeItem"
import { FilterField } from "@dltru/dfa-models"

type Filter = {
  created_at?: FilterField<string>[] | string,
}

export type PeriodFilterProps = {
  onApplyFilters: (filters?: Filter) => void,
  defaultDatePickerValues: [Moment, Moment],
  datePickerDisabled?: DateRangeItemProps['disabled']
}

type RangeValue = Parameters<NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>['onChange']>>[0]
const currentDate = moment()

export const PeriodFilter: FC<PeriodFilterProps> = ({ onApplyFilters, defaultDatePickerValues, datePickerDisabled }) => {
  const [currentType, setCurrentType] = useState<PeriodSelectOptions>(PeriodSelectOptions.DATE_RANGE)

  const onChange = (value: Moment | null) => {
    if (value) {
      const startDate = moment(value).startOf(currentType as moment.unitOfTime.StartOf)
      const endDate = moment(value).endOf(currentType as moment.unitOfTime.StartOf)
      onApplyFilters({ created_at: [`ge(${startDate.format()})`, `lt(${endDate.format()})`] })
    }
  }

  const onDatePickerChange = (values: RangeValue) => {
    if (values?.[0] && values?.[1]) {
      onApplyFilters({ created_at: [`ge(${values[0].format()})`, `lt(${values[1].endOf('day').format()})`] })
    }
  }

  const renderDatePicker = () => {
    if (currentType === PeriodSelectOptions.DATE_RANGE) {
      return <WrappedDateRangeItem
        className={styles.period_filter_date_picker} 
        onlyBeforeDateAndToday={currentDate}
        defaultValue={defaultDatePickerValues}
        onOk={onDatePickerChange}
        disabled={datePickerDisabled}
      />
    } 
    return <WrappedDateItem
      picker={currentType} 
      className={styles.period_filter_date_picker}
      onlyBeforeDateAndToday={currentDate}
      {...mapPeriodNameToPickerProps[currentType]}
      onChange={onChange}
    /> 
  }

  return <div className={styles.period_filter}>
    <Select
      bordered={false}
      onChange={setCurrentType}
      defaultValue={PeriodSelectOptions.DATE_RANGE}
      size="middle"
      options={selectOptions}
      className={styles.period_filter_select}
    >
      {selectOptions.map(({value, label}) => (
        <Select.Option value={value} key={value}>{label}</Select.Option>
      ))}
    </Select>
    {renderDatePicker()}
  </div>
}